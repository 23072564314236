// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
// @import '../node_modules/ng-zorro-antd/ng-zorro-antd.variable.less';
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Custom Color
// @sws-primary-color: #ff0000;
@sws-secondary-color: #b6020b;
// @sws-primary-bg-color: @sws-primary-color;
@sws-secondary-bg-color: @sws-secondary-color;
@sws-color-black: #0b0b0b;
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-size-base: 16px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: @font-size-base - 2px;

// https://github.com/ant-design/ant-design/issues/20210
@line-height-base: 1.5;
@border-radius-base: 4px;
@border-radius-sm: @border-radius-base - 1px;

// Utilities
// ---
@sws-base-padding: 0 24px 12px;

// sider
@layout-sider-background: @sws-secondary-bg-color;

// Menu
// ---
@menu-item-color: @white;
@menu-popup-bg: @sws-secondary-bg-color;

// Form
// ---
@form-item-margin-bottom: 34px;

// Steps
// ---
@steps-icon-size: 36px;
@steps-icon-custom-size: @steps-icon-size;
@steps-icon-custom-font-size: 28px;
@steps-icon-margin: 0;
@steps-small-icon-margin: 0;

// Tabs
// ---
@tabs-card-active-color: @white;
@tabs-ink-bar-color: @sws-secondary-color;
@tabs-highlight-color: @sws-secondary-color;
@tabs-hover-color: @sws-secondary-color;
@tabs-active-color: @white;
@tabs-card-gutter: 0;

// Drawer
// ---
// @drawer-header-padding: @padding-md @padding-lg;
@drawer-body-padding: @sws-base-padding;
@drawer-bg: @component-background;
@drawer-footer-padding-vertical: @modal-footer-padding-vertical;
@drawer-footer-padding-horizontal: @modal-footer-padding-horizontal;
@drawer-header-close-size: 56px;
@drawer-title-font-size: @font-size-lg + 2px;
@drawer-title-line-height: 1.5;

.@{drawer-prefix-cls}-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: @drawer-header-padding;
  font-size: @drawer-title-font-size;
  line-height: @drawer-title-line-height;
  border-bottom: @border-width-base + 1px @border-style-base @sws-color-black;

  &-title {
    flex-direction: row-reverse;

    > .@{drawer-prefix-cls}-close {
      color: rgba(@sws-color-black, 0.65);
      margin-right: 0;
      font-size: @font-size-lg + 2px;
    }
  }
}

.@{drawer-prefix-cls}-title {
  color: @sws-secondary-color;
}

// Modal
// --
@modal-header-padding-vertical: 16px;
@modal-header-padding-horizontal: 24px;
@modal-body-padding: 0 48px 12px;
@modal-header-bg: @component-background;
@modal-header-padding: @modal-header-padding-vertical @modal-header-padding-horizontal;
@modal-header-border-width: 2px;
@modal-header-border-style: @border-style-base;
@modal-header-title-line-height: 1.5;
@modal-header-title-font-size: 20px;
@modal-header-border-color-split: @sws-color-black;
@modal-header-close-size: @modal-header-title-line-height + 2 * @modal-header-padding-vertical;
@modal-heading-color: @sws-secondary-color;
@modal-close-color: rgba(@sws-color-black, 0.65);
@modal-mask-bg: fade(@black, 45%);
@modal-border-radius: @border-radius-base;

.@{dialog-prefix-cls} {
  &-close {
    top: 11px;
    right: 11px;

    &-x {
      font-size: @font-size-lg + 4px;
    }
  }
}

// Disabled states
@disabled-color: fade(@sws-color-black, 63.5%);
@disabled-bg: #f9f9f9;

// Message
// ---
@message-notice-content-padding: 16px 24px;
// @message-notice-content-bg: @component-background;
