@use 'variables' as *;

nz-divider {
  &#{$a}-divider {
    &-horizontal {
      margin: $sws-base-spacing-inline 0;

      &#{$a}-divider-plain {
        border: none;
      }

      &:not(#{$a}-divider-plain) {
        border-top: $sws-base-border;
      }
    }

    &#{$sws-divider}-vertical {
      border-left: 2px solid rgba($sws-color-black, 0.15);
      height: 0.95em;
    }
  }
}
