@use 'variables' as *;

@mixin sws-font-base {
  font-size: $sws-base-font-size;
  font-weight: $sws-base-font-weight;
  font-family: $sws-base-font-family;
}

@mixin sws-font-header {
  font-size: $sws-header-font-size;
  font-weight: $sws-header-font-weight;
  font-family: $sws-base-font-family;
}

@mixin sws-page-header {
  font-size: $sws-header-font-size;
  font-weight: $sws-header-font-weight;
  color: $sws-secondary-color;
}

@mixin sws-page-subheader {
  font-size: $sws-subheader-font-size;
  font-weight: $sws-subheader-font-weight;
  color: $sws-secondary-color;
}
