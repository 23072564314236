@use 'variables' as *;

$sws-color-finish: darken($sws-color-success, 10%);

// color: #1890ff;
// background: #e6f7ff;
// border-color: #91d5ff;

#{$a-steps}-item-custom#{$a-steps}-item {
  &-finish {
    .ant-steps-item-icon {
      > #{$a-steps}-icon {
        color: $sws-color-finish;
      }
    }

    #{$a-steps}-item-container > #{$a-steps}-item-content {
      > #{$a-steps}-item-title {
        &::after {
          height: 2px;
          background-color: $sws-color-finish;
        }
      }
    }
  }

  &-wait {
    .ant-steps-item-icon {
      > #{$a-steps}-icon {
        color: $sws-component-bg-color;
      }
    }
  }

  &-process {
    .ant-steps-item-icon {
      > #{$a-steps}-icon {
        color: $sws-tag-background-color;
        nz-icon {
          background-color: darken($sws-component-color, 22.5%);
          border-color: #1890ff;
          border-width: 1px;
          border-style: solid;
          border-radius: 50%;
        }
      }
    }
  }

  &-wait,
  &-process {
    #{$a-steps}-item-container > #{$a-steps}-item-content {
      > #{$a-steps}-item-title {
        &::after {
          height: 2px;
          background-color: $sws-component-bg-color;
        }
      }
    }
  }

  // &-content {
  //   // display: none;
  //   #{$a-steps}-item-title {
  //     &::after {
  //       height: 2px;
  //       background-color: $sws-secondary-bg-color;
  //     }
  //   }
  // }
}
