@use 'variables' as *;

$a-upload: #{$a}-upload;
$upload-border: $sws-base-input-border-width dashed $sws-base-input-border-color;

#{$a-upload}-list-picture-card-container {
  > #{$a-upload}-list-item {
    padding: 3px;
    border: $upload-border;
  }
}
