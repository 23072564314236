@use 'variables' as *;

nz-tag#{$a}-tag#{$sws-tag} {
  padding: 0;
  margin-block: 0;
  margin-inline: auto;
  width: max(74px);
  height: max(28px);
  text-align: center;
  line-height: 24px;
  font-size: $sws-base-font-size - 2px;
}
