@use 'variables' as *;

form#{$a-form} {
  &#{$sws-search-form} {
    nz-form-label > label {
      font-weight: $sws-base-font-weight + 100;
      color: darken($sws-component-color, 40%);
      > span {
        margin-left: $sws-base-spacing-inline;
      }
    }

    #{$a-checkbox}-wrapper-in-form-item {
      > #{$a-checkbox} {
        + span,
        + span > span {
          color: darken($sws-component-color, 40%);
        }
      }
    }
  }
  &#{$a-form}-vertical {
    padding-block: 24px;

    #{$a-form}-item {
      &-label {
        > label {
          margin-right: $sws-base-spacing-inline;
          text-wrap: nowrap;

          &#{$a-form}-item-required:not(#{$a-form}-item-required-mark-optional) {
            &::before {
              display: none;
            }
          }

          span.anticon.sws-text-info {
            color: $sws-text-info;
            margin-inline: $sws-base-spacing-inline;
            cursor: pointer;
          }
        }
      }

      &-explain-error {
        font-size: $sws-sm-font-size;
      }
    }

    nz-form-text {
      margin-inline: $sws-base-spacing-inline;
      padding-inline: 0;

      &::before {
        position: relative;
        display: inline-block;
        margin: 0;
        margin-right: $sws-base-spacing-inline;
        color: $sws-color-black;
        font-size: var(--sws-base-font-size);
        font-weight: $sws-base-font-weight + 100;
        font-family: var(--sws-font-base);
        line-height: 1.5;
        content: '\003A';
      }
    }
  }
}
