@use 'variables' as *;

nz-radio-group#{$a-radio}-group {
  height: $sws-base-input-height;

  &.ng-dirty.ng-invalid {
    > #{$sws-radio-wrapper} > #{$a-radio} {
      > #{$a-radio}-input {
        + #{$a-radio}-inner {
          border-color: $sws-color-error;
        }
      }
    }
  }

  > #{$sws-radio-wrapper} {
    > #{$a-radio} {
      font-size: $sws-base-icon-size;

      > input[type='radio']#{$a-radio}-input {
        width: 22px;
        height: 22px;

        + #{$a-radio}-inner {
          width: 22px;
          height: 22px;
          border-color: $sws-base-input-border-color;
          border-width: $sws-base-input-border-width;
          background-color: $sws-radio-bg-color;

          &::after {
            width: 22px;
            height: 22px;
            border-radius: 24px;
            margin-left: -11px;
            margin-top: -11px;
            background-color: $sws-radio-color;
          }
        }

        &:focus + #{$a-radio}-inner {
          box-shadow: none;
        }
      }

      &-disabled + span {
        color: $sws-base-input-disable-color;
      }
    }
  }
}
