@use 'variables' as *;

#{$a-picker} {
  width: 100%;
  padding: $sws-base-input-padding;
  border: $sws-base-input-border;
  height: $sws-base-input-height;

  &:disabled,
  &#{$a-picker}-disabled {
    background: $sws-base-input-disable-bg-color;
    color: $sws-base-input-disable-color;
    border: $sws-base-input-border;
  }

  &:hover,
  &:hover#{$a-picker}-focused {
    border-color: $sws-base-input-border-color;
    border-right-width: 2px;
  }

  &#{$a-picker}-focused {
    box-shadow: none;
  }

  &-suffix,
  &-clear {
    margin-left: 0;

    > nz-icon#{$a-icon} {
      font-size: $sws-base-icon-size + 2px;
      color: $sws-component-color;
    }
  }

  &-input {
    > input {
      font-size: $sws-base-input-font-size;
      height: 100%;
      border-radius: 0;

      &:disabled {
        color: inherit;
      }
    }
  }

  &#{$a-picker}-status-error {
    &#{$a-picker}-focused,
    &:focus {
      box-shadow: none;
      border-right-width: 2px;
      border-color: $sws-color-error;
    }

    #{$a-picker}-suffix > nz-icon {
      color: $sws-color-error;
    }
  }
}

#{$a-picker}-range {
  > #{$a-picker}-active-bar {
    background: transparent;
  }

  > #{$a-picker}-clear {
    right: 8px;
  }
}
