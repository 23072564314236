/* You can add global styles to this file, and also import other style files */
@use 'variables' as *;

@forward 'fonts';
@forward 'typography';
@forward 'utilities';
@forward 'icon';
@forward 'tag';
@forward 'button';
@forward 'input';
@forward 'form';
@forward 'tooltip';
@forward 'cascader';
@forward 'select';
@forward 'table';
@forward 'divider';
@forward 'checkbox';
@forward 'radio';
@forward 'steps';
@forward 'picker';
@forward 'upload';
@forward 'modal';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app,
#app > * {
  font-family: var(--sws-font-base);
  font-size: var(--sws-base-font-size);
  font-weight: var(--sws-base-font-weight);
  font-style: normal;
}

a:hover,
a:focus {
  text-decoration: none;
}

.ant-form-item-label > label > span.anticon {
  &.label-info {
    color: var(--cyan);
    font-size: 16px;
  }
}

// principal information
.cdk-overlay-container {
  #{$a-menu}-submenu#{$a-menu}-submenu-popup {
    border-radius: 0;
    &#{$a-menu}-submenu-placement-right {
      left: 0;
    }
    &::before {
      top: 0;
    }
    > #{$a-menu} {
      &#{$a-menu}-vertical#{$a-menu}-sub {
        border-radius: 0;
        #{$a-menu}-item {
          &:hover {
            color: $sws-color-white;
            a:hover {
              color: inherit;
            }
          }
          &-selected {
            background-color: #da1212;
            a,
            a:hover {
              color: $sws-color-white;
            }
          }
        }
      }
    }
  }

  .sws-principal-dropdown {
    width: 100%;
    margin-top: 0;
    > #{$a}-dropdown-menu#{$a}-dropdown-menu-root {
      padding: 2px 0;
      > #{$a}-dropdown-menu-item {
        padding: 6px $sws-base-spacing-inline;
        font-size: 14px;
        transition: background-color 0.3s;

        &:not(#{&}-selected) {
          color: $sws-secondary-color;
        }
        &-selected {
          background-color: $sws-component-bg-color;
          color: $sws-color-white;
        }
      }
    }
  }
}

.custom-confirm-modal {
  .ant-modal-body {
    .ant-modal-confirm-body {
      .ant-modal-confirm-content {
        margin-top: 16px;
      }
    }

    span.note-info {
      font-weight: bolder;
      color: var(--danger);
    }
    .ant-modal-confirm-btns {
      float: none;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      > button.ant-btn {
        min-width: 100px;
        margin-right: 8px;
      }
    }
  }
}

.ant-spin-dot.global-spinner,
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot.global-spinner {
  margin-top: -32px;
}

// pagination dropdown
.sws-page-options-dropdown {
  > #{$a-select}-dropdown {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 2px;
    padding: 0;
  }
  #{$a-select}-item#{$a-select}-item-option {
    font-size: $sws-sm-font-size;
    padding: 5px 14px;

    &#{$a-select}-item-option-selected {
      font-weight: $sws-header-font-weight;
      background-color: $sws-secondary-bg-color;
      color: $sws-color-white;
    }

    &#{$a-select}-item-option-active:not(#{$a-select}-item-option-disabled):not(#{$a-select}-item-option-selected) {
      background-color: $sws-color-white;
    }
  }
}
