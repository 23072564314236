@use 'variables' as *;

$sws-checkbox-bg-color: #3ab0ff;

.sws-checkbox-group {
  > #{$a-checkbox}-wrapper {
    &.ant-checkbox-group-item {
      margin-right: $sws-base-spacing-inline;
    }

    > #{$a-checkbox} {
      input[type='checkbox'] {
        width: $sws-base-icon-size + 2px;
        height: $sws-base-icon-size + 2px;
      }

      &#{$a-checkbox}-checked {
        #{$a-checkbox}-inner {
          background-color: $sws-checkbox-bg-color;
          border-color: $sws-checkbox-bg-color;

          &::after {
            border-color: $sws-color-white;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg) scale(1.15) translate(-50%, -50%);
          }
        }

        &::after {
          border: 2px solid $sws-checkbox-bg-color;
        }
      }

      #{$a-checkbox}-inner {
        border: 2px solid $sws-checkbox-bg-color;
        border-radius: $sws-base-border-radius;
        width: $sws-base-icon-size + 2px;
        height: $sws-base-icon-size + 2px;

        &::after {
          border: 2px solid $sws-checkbox-bg-color;
          width: 5.72px;
          height: 10.15px;
        }
      }

      + span,
      + span > span {
        font-size: $sws-base-font-size;
        font-weight: $sws-base-font-weight;
      }
    }
  }
}
