// Montserrat
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
}

// Poppins
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-regular.ttf') format('truetype'),
    url('/assets/fonts/poppins/poppins-regular.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-italic.ttf') format('truetype'),
    url('/assets/fonts/poppins/poppins-italic.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-italic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-medium.ttf') format('truetype'),
    url('/assets/fonts/poppins/poppins-medium.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-medium.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-mediumitalic.ttf') format('truetype'),
    url('/assets/fonts/poppins/poppins-mediumitalic.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-mediumitalic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-semibold.ttf') format('truetype'),
    url('/assets/fonts/poppins/poppins-semibold.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-semibold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-semibolditalic.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-semibolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-bold.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-bold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-bolditalic.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-black.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-black.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/poppins/poppins-blackitalic.woff2') format('woff2'),
    url('/assets/fonts/poppins/poppins-blackitalic.woff') format('woff');
}

// Roboto
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/roboto/roboto-light.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/roboto/roboto-lightitalic.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-lightitalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/roboto/roboto-regular.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/roboto/roboto-italic.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/roboto/roboto-medium.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('/assets/fonts/roboto/roboto-mediumitalic.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-mediumitalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/roboto/roboto-bold.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('/assets/fonts/roboto/roboto-bolditalic.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('/assets/fonts/roboto/roboto-black.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-black.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('/assets/fonts/roboto/roboto-blackitalic.woff2') format('woff2'),
    url('/assets/fonts/roboto/roboto-blackitalic.woff') format('woff');
}

// url('/assets/fonts/notosans/notosans-italic-variable.woff2') format('woff2 supports variations'),
//     url('/assets/fonts/notosans/notosans-italic-variable.woff2') format('woff2-variations'),

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900
// .noto-sans-<uniquifier > {
//   font-family: 'Noto Sans', serif;
//   font-optical-sizing: auto;
//   font-weight: <weight>;
//   font-style: normal;
//   font-variation-settings: 'wdth' 100;
// }
