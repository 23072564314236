@use 'variables' as *;

#{$sws-select} {
  font-size: $sws-base-input-font-size;
  height: $sws-base-input-height;

  &#{$a-select}-multiple > :not(#{$a-select}-customize-input)#{$a-select}-selector {
    > #{$a-select}-selection-item {
      margin-top: 1px;
      margin-bottom: 1px;
      height: 30px;
      line-height: 26px;
    }
  }

  > :not(#{$a-select}-customize-input)#{$a-select}-selector {
    border: $sws-base-input-border;
    height: $sws-base-input-height;

    #{$a-select}-selection-item {
      line-height: $sws-base-input-height - 4px;
    }

    > #{$a-select}-selection-search {
      input#{$a-select}-selection-search-input {
        height: $sws-base-input-height - 4px;
      }
    }
  }

  &-disabled {
    #{$a-select}-selector {
      background: $sws-base-input-disable-bg-color;
      color: $sws-base-input-disable-color;
    }
  }

  &:not(#{$a-select}-disabled):hover,
  &#{$a-select}-status-error#{$a-select}-focused:not(#{$a-select}-disabled)#{$a-select}:not(#{$a-select}-customize-input),
  &#{$a-select}-focused:not(#{$a-select}-disabled)#{$a-select}:not(#{$a-select}-customize-input) {
    #{$a-select}-selector {
      border-color: $sws-base-input-border-color;
      border-right-width: $sws-base-input-border-width;
      box-shadow: none;
    }
  }

  &#{$a-select}-status-error {
    > nz-select-clear,
    > nz-select-arrow {
      color: $sws-color-error;
    }
  }

  > nz-select-clear {
    color: $sws-base-input-border-color;
    > #{$a-icon} {
      font-size: $sws-base-icon-size;
    }
  }

  > nz-select-arrow {
    color: $sws-base-input-border-color;
    right: 7px;

    > #{$a-icon} {
      font-size: $sws-base-icon-size + 2px;
    }
  }
}

.sws-option-wrapper {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: center;

  .sws-option-selected {
    margin-left: 8px;
    font-size: $sws-base-input-font-size;
  }
}

#{$a-select}.sws-select-lang {
  width: 108px;
  // height: 28px;
  color: $sws-secondary-color;
  font-size: $sws-base-font-size - 1px;

  &:not(#{$a-select}-disabled) {
    &:hover {
      > #{$a-select}-selector {
        border-color: $sws-secondary-color;
        border-right-width: 2px;
      }
    }
  }
  &#{$a-select}-focused:not(#{$a-select}-disabled) {
    > #{$a-select}-selector {
      border-color: $sws-secondary-color;
      border-right-width: 2px;
      box-shadow: none;
    }
  }
  > #{$a-select}-selector {
    // height: 28px;
    border-radius: 0;
    border: 2px solid $sws-secondary-color;
    font-size: $sws-base-font-size - 1px;
    line-height: $sws-base-line-height;

    > #{$a-select}-selection-item {
      line-height: 28px;
    }
  }

  > #{$a-select}-arrow {
    color: $sws-secondary-color;
    font-size: $sws-base-icon-size + 2px;
    height: $sws-base-icon-size + 2px;
    margin-top: -9px;
    right: 8px;
  }
}
