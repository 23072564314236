@use 'variables' as *;

.sws-text-muted,
.text-muted {
  color: $sws-text-muted;
}

.sws-text-required {
  position: relative;
  display: inline-block;
  margin-block: 0;
  margin-inline: 8px;
  color: $sws-primary-color;
  font-size: var(--sws-base-font-size);
  font-family: var(--sws-font-base);
  line-height: 1.5;
}

.sws-text-info {
  color: $sws-text-info;
}
