@use 'variables' as *;

input {
  &.sws-input {
    padding: $sws-base-input-padding;
    border: $sws-base-input-border;
    font-size: $sws-base-input-font-size;
    height: max($sws-base-input-height);

    &:hover {
      border: $sws-base-input-border;
    }

    &:focus,
    &#{$a-input}:focus {
      border: $sws-base-input-border;
      box-shadow: none;
      outline: 0;
    }

    &:disabled {
      border: $sws-base-input-border;
      background-color: $sws-base-input-disable-bg-color;
      color: $sws-base-input-disable-color;
      &:hover {
        border-color: $sws-component-color;
        border-right-width: 2px;
      }
    }

    &#{$a-input}-status-error {
      &:not(#{$a-input}-disabled):not(#{$a-input}-borderless):focus {
        box-shadow: none;
        border-width: 2px;
      }
    }
  }

  &:-internal-autofill-selected {
    background-image: none !important;
    background-color: transparent !important;
    color: inherit !important;
  }
}

nz-input-number#{$sws-input-number} {
  width: 100%;
  border: $sws-base-input-border;
  font-size: $sws-base-input-font-size;
  height: max($sws-base-input-height);

  #{$a-input-number}-input {
    height: max($sws-base-input-height - 4px);
    padding: $sws-base-input-padding;
  }
}

nz-input-group,
nz-input-number-group {
  &#{$sws-input} {
    border: $sws-base-input-border;
    font-size: $sws-base-input-font-size;

    &:not(#{$a-input}-affix-wrapper-disabled):hover {
      border: $sws-base-input-border;
      z-index: 0;
    }

    > #{$a-input} {
      padding: $sws-base-input-padding;
    }

    &#{$a-input}-affix-wrapper:focus,
    &#{$a-input}-affix-wrapper-focused {
      box-shadow: none;
      border-color: $sws-base-input-border-color;
      border-right-width: $sws-base-input-border-width;

      &:not(#{$a-input}-affix-wrapper-disabled):not(#{$a-input}-affix-wrapper-borderless) {
        &#{$a-input}-affix-wrapper-status-error {
          box-shadow: none;
          border-color: $sws-color-error;
          border-right-width: $sws-base-input-border-width;
        }
      }
    }

    #{$a-input}-clear-icon {
      font-size: $sws-base-icon-size;
      color: $sws-base-input-border-color;

      &:hover {
        color: $sws-base-input-border-color;
      }
    }

    &#{$sws}-password-input-group {
      padding: $sws-base-input-padding;

      > #{$a-input} {
        padding: 0;
        font-size: $sws-base-input-font-size;
      }
    }
  }
}

nz-input-group#{$sws-input-group} {
  display: inline-flex;
  border-radius: 4px;
  border: $sws-base-input-border;
  font-size: $sws-base-input-font-size;

  &:not(#{$a-input}-affix-wrapper-status-error)#{$a-input}-affix-wrapper-textarea-with-clear-btn {
    > textarea + span#{$a-input}-suffix {
      > .anticon {
        color: $sws-base-input-color;
      }
    }
  }

  &#{$a-input}-affix-wrapper-disabled {
    background-color: $sws-base-input-disable-bg-color;
    color: $sws-base-input-disable-color;
    border-right-width: $sws-base-input-border-width;

    > #{$a-input}:disabled {
      color: $sws-base-input-disable-color;
      background-color: $sws-base-input-disable-bg-color;
    }
    &:hover {
      border: $sws-base-input-border;
    }
  }

  &#{$a-input}-affix-wrapper-status-error,
  &#{$sws}-status-error {
    border-color: $sws-color-error;

    #{$a-select} {
      border-right-color: $sws-color-error;

      #{$a-select}-arrow,
      #{$a-select}-clear {
        color: $sws-color-error;
      }
    }
  }

  nz-cascader#{$a-select},
  #{$a-select} {
    position: relative;
    width: 228px;
    border-right: $sws-base-input-border;
    font-size: $sws-base-input-font-size;

    &#{$a-select}-disabled:not(#{$a-select}-customize-input) {
      #{$a-select}-selector {
        background-color: $sws-base-input-disable-bg-color;
        color: $sws-base-input-disable-color;
      }
    }

    &#{$a-select}-focused:not(#{$a-select}-disabled)#{$a-select}:not(#{$a-select}-customize-input) {
      ::ng-deep {
        #{$a-select}-selector {
          border-color: transparent;
          border-right-width: 0;
          box-shadow: none;
        }
      }
    }

    &:not(#{$a-select}-customize-input) > #{$a-select}-selector {
      padding: 4px 8px;
      border: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      > #{$a-select}-selection-search {
        left: 4px;
        right: 19px;
        height: 100%;

        > #{$a-select}-selection-search-input {
          height: 100%;
        }
      }

      > #{$a-select}-selection-item {
        line-height: $sws-base-line-height;
      }

      > #{$a-select}-selection-placeholder {
        line-height: $sws-base-line-height;
        padding-right: $sws-base-spacing-inline;
      }
    }

    #{$a-select}-arrow,
    #{$a-select}-clear {
      color: $sws-base-input-color;
    }

    #{$a-select}-arrow {
      font-size: $sws-base-icon-size;
      line-height: $sws-base-line-height;
      right: 4px;

      #{$a-icon} {
        font-size: $sws-base-icon-size + 2px;
        line-height: $sws-base-line-height;
      }
    }

    #{$a-select}-clear {
      right: 7px;
      #{$a-icon} {
        font-size: $sws-base-icon-size;
        line-height: $sws-base-line-height;
      }
    }
  }

  input[type='text'] {
    position: relative;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 4px 8px;
    list-style: none;
    line-height: 1.5;
    font-size: $sws-base-input-font-size;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    display: inline-block;
    width: 100%;
    min-width: 0;
    color: rgba(0, 0, 0, 0.85);
    background-color: $sws-bg-white;
    background-image: none;
    border: none;
    transition: all 0.3s;

    &#{$a-input}-status-error:not(#{$a-input}-disabled)#{$a-input} {
      border-color: transparent;
      box-shadow: none;
      border-right-width: $sws-base-input-border-width;
    }

    &:disabled {
      background-color: $sws-base-input-disable-bg-color;
      color: $sws-base-input-disable-color;
    }
  }

  &#{$a-input}-affix-wrapper {
    padding: 0;

    &:not(#{$a-input}-affix-wrapper-disabled):hover,
    &:focus,
    &#{$a-input}-affix-wrapper-focused {
      border-color: $sws-base-input-border-color;
      border-right-width: $sws-base-input-border-width;
      box-shadow: none;
    }

    > nz-date-picker {
      border: none;
      border-right: $sws-base-input-border;
      padding: $sws-base-input-padding;
      font-size: $sws-base-input-font-size;
      border-radius: 0;
    }
  }
}

sws-input-csc,
sws-input-phonenumber {
  width: 100%;
}

nz-input-number#{$a-input-number} {
  width: 100%;
  border: $sws-base-input-border;
  font-size: $sws-base-input-font-size;

  &:hover,
  &-focused {
    border-color: $sws-base-input-border-color;
    border-right-width: $sws-base-input-border-width;
    box-shadow: none;
  }

  #{$a-input-number}-input {
    height: $sws-base-input-height - 4px;
    padding: $sws-base-input-padding;
    font-size: $sws-base-input-font-size;
    line-height: 1.5;
  }

  &#{$a-input-number}-status-error:not(#{$a-input-number}-disabled):not(#{$a-input-number}-borderless) {
    &:focus,
    &#{$a-input-number}-focused {
      border-color: $sws-color-error;
      box-shadow: none;
      border-right-width: 2px;
    }
  }
}
