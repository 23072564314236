@use 'variables' as *;

#{$a-table},
#{$a-table}#{$a-table}-bordered {
  > #{$a-table}-container {
    > #{$a-table}-content {
      > table {
        #{$a-table}-thead,
        #{$a-table}-tbody {
          > tr {
            &:first-child {
              > th:first-child {
                border-top-left-radius: $sws-base-border-radius;
                border-left: $sws-base-border;
              }
            }

            > th {
              padding: $sws-table-cell-padding;
              background: $sws-component-bg-color;
              color: $sws-color-white;
              border: $sws-base-border;
              border-left: none;
              text-align: center;
            }

            > td {
              padding: $sws-table-cell-padding;
              border: $sws-base-border;
              border-top: none;
              border-left: none;
              text-align: center;

              &:first-child {
                border-left: $sws-base-border;
              }
            }
          }
        }

        tbody tr {
          &:nth-of-type(even):not(.ant-table-placeholder) {
            background: $sws-title-bg-color;
          }
        }
      }
    }
  }
}
