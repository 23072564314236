@use 'variables' as *;
@use 'mixins' as *;

button,
[nz-button] {
  &#{$sws-btn} {
    width: max($sws-base-button-width);
    height: max($sws-base-button-height);
    display: inline-block;
    text-transform: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 0;
    border: $sws-base-border;
    border-radius: $sws-base-border-radius;
    line-height: $sws-base-line-height;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background-color: $sws-button-primary-bg;
    color: $sws-button-primary-color;

    @include sws-font-base;

    // type
    &-secondary {
      background-color: $sws-button-secondary-bg;
      color: $sws-button-secondary-color;
    }

    &-reset {
      background-color: $sws-color-reset;
      color: $sws-color-black;

      &:hover,
      &:active,
      &:focus {
        background-color: $sws-color-reset;
        color: $sws-color-black;
      }
    }

    &-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      line-height: 1.5;
      font-size: 14px;
      width: 100px;
      border: 1px $sws-base-input-border-style $sws-component-bg-color;
      border-radius: 90px;
      background: $sws-component-bg-color;
      color: $sws-color-white;
      transition: none;

      &:hover,
      &:focus,
      &:active {
        border-color: $sws-secondary-color;
      }

      &:disabled {
        background: rgba(#ee4343, 0.9);
        cursor: not-allowed;

        &:hover {
          background: rgba(#ee4343, 0.9);
          color: $sws-color-white;
        }
      }

      > nz-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin-left: 2px;
        margin-right: 2px;

        &#{$sws}-navigation-finish {
          border-radius: 24px;
        }
      }

      > span,
      #{$sws}-navigation-text {
        margin-left: $sws-base-spacing-inline + 4px;

        &#{$sws}-navigation-prev {
          margin-right: $sws-base-spacing-inline + 4px;
        }
      }
    }

    &-block {
      width: 100%;
    }

    &-icon {
      border: none;
      width: max(32px);
      height: max(32px);
      background-color: transparent;
      color: $sws-secondary-color;
    }

    &:not(#{$sws-btn}-navigation):active,
    &:not(#{$sws-btn}-navigation):focus {
      outline: 0;
    }

    &:not(#{$sws-btn}-navigation):hover,
    &:not(#{$sws-btn}-navigation):focus {
      border-color: $sws-color-black;
    }

    // size
    &-lg {
      width: max($sws-lg-button-width);
      height: max($sws-lg-button-height);
    }

    &-sm {
      width: max($sws-sm-button-width);
      height: max($sws-sm-button-height);
    }

    &-xs {
      width: max($sws-xs-button-width);
      height: max($sws-xs-button-height);
    }

    &-borderless {
      border-color: transparent;
      &:hover,
      &:active,
      &:focus {
        border-color: transparent;
      }
    }

    :not(#{$sws-btn}-navigation) > span + .anticon {
      margin-left: $sws-base-spacing-inline;
    }

    &-danger {
      color: $sws-primary-color;

      &[disabled] {
        color: rgba($sws-primary-color, 0.45);
      }
    }

    // shape
    &#{$sws-btn}-circle#{$sws-btn}-icon {
      @extend #{$sws-btn}-icon;
      padding: 0;
      margin: 0;
      vertical-align: -3px;
      background-color: $sws-color-white;
      color: $sws-color-black;
      border-radius: 50%;
      border: none;

      outline: 0;
      > * {
        font-size: 28px;
        line-height: 1.5;
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba($sws-button-primary-bg, 0.85);
    }
  }

  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    -webkit-appearance: button;
    appearance: button;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
  }
}
