@use 'variables' as *;

#{$a-menu}#{$a-menu}-inline-collapsed > #{$a-menu}-item {
  #{$a-icon} {
    font-size: $sws-menu-icon-size;
  }
}
#{$a-menu}-item,
#{$a-menu}-submenu-title {
  #{$a-icon} {
    font-size: $sws-menu-icon-size;
  }
}

.sws-flag-icon {
  width: 27px;
  height: 18px;
}
