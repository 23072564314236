@use 'sass:color';
:root {
  // --sws-primary-color: #ff0000;
  // --sws-secondary-color: #b6020b;
  // --sws-primary-bg-color: #ff0000;
  // --sws-secondary-bg-color: #b6020b;

  --sws-button-primary-bg: #ef0000;
  --sws-button-secondary-bg: #f4ecd4;

  --sws-light-base-color: #f3f3f3;
  --sws-black-base-color: #0b0b0b;
  --sws-base-border-radius: 4px;

  --sws-font-poppins: 'Poppins', sans-serif;
  --sws-font-notosans: 'Noto Sans', serif;
  --sws-font-roboto: 'Roboto', serif;
  --sws-font-base: var(--sws-font-roboto);

  --sws-base-font-size: 16px;
  --sws-base-font-weight: 400;
  --sws-base-line-height: 1.5;

  --sws-spacing-inline: 12px;
}

$a: '.ant';
$a-icon: '.anticon';
$a-menu: #{$a}-menu;
$a-page-header: #{$a}-page-header;
$a-form: #{$a}-form;
$a-input: #{$a}-input;
$a-tooltip: #{$a}-tooltip;
$a-select: #{$a}-select;
$a-menu: #{$a}-menu;
$a-collapse: #{$a}-collapse;
$a-table: #{$a}-table;
$a-checkbox: #{$a}-checkbox;
$a-paged: #{$a}-pagination;
$a-input-number: #{$a-input}-number;
$a-steps: #{$a}-steps;
$a-picker: #{$a}-picker;
$a-radio: #{$a}-radio;
$a-cascader: #{$a}-cascader;
$a-btn: #{$a}-btn;
$a-pagination: #{$a}-pagination;
$a-modal: #{$a}-modal;

$sws: '.sws';
$sws-btn: #{$sws}-btn;
$sws-input: #{$sws}-input;
$sws-input-number: #{$sws-input}-number;
$sws-input-group: #{$sws-input}-group;
$sws-select: #{$sws}-select;
$sws-search-form: #{$sws}-search-form;
$sws-table: #{$sws}-table;
$sws-paged: #{$sws}-pagination;
$sws-tag: #{$sws}-tag;
$sws-divider: #{$sws}-divider;
$sws-tooltip: #{$sws}-tooltip;
$sws-radio-wrapper: #{$sws}-radio-wrapper;

// Color
$sws-primary-color: #ff0000;
$sws-secondary-color: #b6020b;
$sws-primary-bg-color: $sws-primary-color;
$sws-secondary-bg-color: $sws-secondary-color;
$sws-title-bg-color: #fffdef; // #fffae7;
$sws-component-bg-color: #da1212;
$sws-component-color: #3ab0ff;
$sws-component-text-color: darken($sws-component-color, 40%);
$sws-bg-white: #fff;

$sws-color-light: #f3f3f3;
$sws-color-black: #0b0b0b;
$sws-color-white: #fff;
$sws-color-error: #ff4d4f;
$sws-color-reset: #f9d689;
$sws-color-success: #52c41a;
$sws-text-info: #17a2b8;
$sws-text-required: $sws-primary-color;
$sws-text-muted: #7f7f7f;

/** Utility */
$sws-base-line-height: 1.5;
$sws-base-font-family: 'Roboto', serif;
$sws-base-font-size: 16px;
$sws-base-font-weight: 400;
$sws-sm-font-size: $sws-base-font-size - 2px;
$sws-lg-font-size: $sws-base-font-size + 2px;
$sws-base-icon-size: 16px;
$sws-menu-icon-size: $sws-base-icon-size + 4px;

$sws-title-font-size: $sws-base-font-size + 6px;
$sws-header-font-size: $sws-base-font-size + 4px;
$sws-subheader-font-size: $sws-header-font-size - 2px;
$sws-header-font-weight: $sws-base-font-weight + 100;
$sws-subheader-font-weight: $sws-header-font-weight;

// Spacing
$sws-base-spacing: 24px;
$sws-base-spacing-inline: 12px;
$sws-base-layout-padding: 0 24px;
$sws-page-layout-padding: 20px 0;

// Border
$sws-base-border-radius: 4px;
$sws-base-border: 2px solid $sws-color-black;
$sws-primary-border: 2px solid $sws-primary-color;

/** Components */
// Button
$sws-base-button-width: max(200px);
$sws-base-button-height: max(38px);
$sws-lg-button-width: $sws-base-button-width + 20px;
$sws-lg-button-height: $sws-base-button-height - 2px;
$sws-sm-button-width: $sws-base-button-width - 40px;
$sws-sm-button-height: $sws-base-button-height - 2px;
$sws-xs-button-width: $sws-base-button-width - 80px;
$sws-xs-button-height: $sws-base-button-height - 2px;

$sws-button-primary-bg: $sws-secondary-bg-color;
$sws-button-secondary-bg: #f4ecd4;
$sws-button-primary-color: $sws-color-white;
$sws-button-secondary-color: $sws-color-black;

// Input
// becbff // border-color: #b2deec
$sws-base-input-color: $sws-component-color;
$sws-base-input-border: 2px solid $sws-component-color;
$sws-base-input-border-color: $sws-base-input-color;
$sws-base-input-disable-bg-color: #f9f9f9;
$sws-base-input-disable-color: rgba($sws-color-black, 0.635);
$sws-base-input-border-width: 2px;
$sws-base-input-border-style: solid;

$sws-base-input-padding: 4px 8px;
$sws-base-input-font-size: $sws-sm-font-size + 1;
$sws-base-input-height: 36px;

// Table
$sws-table-cell-padding: 6px 10px;

// Radio
$sws-radio-color: color.adjust($sws-component-color, $blackness: 22.5%);
$sws-radio-bg-color: #e6f7ff;

// Tag
$sws-tag-background-color: #e6f7ff;
