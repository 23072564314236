@use 'variables' as *;

:not(.ant-input-group) > nz-cascader {
  &#{$a-cascader} {
    font-size: $sws-base-input-font-size;

    &#{$a-select}-status-error {
      &#{$a-select}:not(#{$a-select}-disabled):not(#{$a-select}-customize-input):not(#{$a-pagination}-size-changer) {
        #{$a-select}-selector {
          border-color: $sws-color-error;
        }

        #{$a-select}-arrow {
          color: $sws-color-error;
        }
      }
    }
    &#{$a-select} {
      &:not(#{$a-select}-customize-input) {
        &#{$a-select}-focused:not(#{$a-select}-disabled) {
          > #{$a-select}-selector {
            border: $sws-base-input-border;
            border-right-width: $sws-base-input-border-width;
            box-shadow: none;
          }
        }
        > #{$a-select}-selector {
          border: $sws-base-input-border;
          height: $sws-base-input-height;
          font-size: $sws-base-input-font-size;
          padding: $sws-base-input-padding;

          > #{$a-select}-selection-placeholder,
          #{$a-select}-selection-item {
            line-height: 1.5;
          }
        }

        > #{$a-select}-arrow {
          font-size: $sws-base-icon-size + 2px;
          line-height: 1.5;
          color: $sws-component-color;
        }
      }
    }
  }
}
