@use 'variables' as *;

.sws-mt-2 {
  margin-top: $sws-base-spacing !important;
}

.sws-mr-1 {
  margin-right: $sws-base-spacing-inline !important;
}

.sws-ml-1 {
  margin-left: $sws-base-spacing-inline !important;
}

.sws-my-2 {
  margin-block: $sws-base-spacing !important;
}

.sws-px-2 {
  padding-inline: $sws-base-spacing !important;
}

.sws-float-right {
  float: right;
}

.sws-w-100 {
  width: 100%;
}

.sws-text-left {
  text-align: left;
}

.sws-text-right {
  text-align: right;
}
